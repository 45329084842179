import LanguageEnum from "./enum/LanguageEnum";

const API_PROTOCOL = "http";
const API_HOST = "localhost";
const API_PORT = "8888";
const API_PATH = "/api";

const API_FULL_PATH = API_PROTOCOL + "://" + API_HOST + ":" + API_PORT + API_PATH;

const API_END_POINT_READER_INFO = API_FULL_PATH + "/readerinfo";
const API_END_POINT_TAGS = API_FULL_PATH + "/tags";

const DEFAULT_LANGUAGE = LanguageEnum.EN;

export default {
    API_PROTOCOL,
    API_HOST,
    API_PORT,
    API_PATH,
    API_FULL_PATH,
    API_END_POINT_READER_INFO,
    API_END_POINT_TAGS,
    DEFAULT_LANGUAGE
}

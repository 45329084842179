import NetworkUtil from "../../../../util/NetworkUtil";
import Config from "../../../../Config";
import Type from "../../../../enum/TypeEnum";
import ErrorCodeEnum from "../../../../enum/ErrorCodeEnum";
import TagsUtil from "../../../../util/TagsUtil";

class MultipleCheckoutFromRFID {

    constructor(jQuery, swal, languageManager) {
        this._jQuery = jQuery;
        this._swal = swal;
        this._languageManager = languageManager;

        this.append(this.getSelectorToAppend(), this.getObjectToAppend());
        this.attachEventHandler(this.getSelectorToEventHandle(), this.triggerScan.bind(this))
    }

    getItemsOrReject(items) {
        if (items.length === 0) {
            return Promise.reject({
                id: ErrorCodeEnum.NOT_FOUND,
                message: this._languageManager.resolveKey('items.notFound')
            });
        }

        return Promise.resolve(items);
    }

    triggerScan(event) {
        event.preventDefault();

        Promise.resolve(this._jQuery)
            .then(jQuery => new NetworkUtil(jQuery)
                .get(Config.API_END_POINT_TAGS)
                .then(tagsResponse => TagsUtil.getTagsOrReject(tagsResponse)
                    .then(tags => TagsUtil.filterTags(tags, Type.ITEM))
                    .then(items => this.getItemsOrReject(items)
                        .then(items => this.buildItemsListForView(items))
                        .then(items => this._jQuery(this.getSelectorToSearchInput()).val(items)))
                )
            )
            .catch(error => this._swal.fire({
                icon: 'error',
                title: error.status,
                text: error.message || this._languageManager.resolveKey('cantConnect'),
            }));
    }

    buildItemsListForView(items) {
        return items
            .map(item => item.pid)
            .reduce((accumulator, currentValue) => accumulator + "\n" + currentValue);
    }

    getObjectToAppend() {
        const btnText = this._languageManager.resolveKey('items.scan');
        return `<button id=\"autobscan\" class=\"scan\">${btnText}</button>`;
    }

    getSelectorToAppend() {
        return "#circ_circulation_issue > fieldset.action > input.button";
    }

    getSelectorToEventHandle() {
        return "#autobscan";
    }

    getSelectorToSearchInput() {
        return "#barcodelist";
    }

    append(selector, objectToAppend) {
        return this._jQuery(objectToAppend).insertAfter(selector);
    }

    attachEventHandler(selector, handler) {
        return this._jQuery(selector).click(handler);
    }
}

export default MultipleCheckoutFromRFID;

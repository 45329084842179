import NetworkUtil from "../../util/NetworkUtil";
import Config from "../../Config";
import Tags from "../../model/Tags";
import Type from "../../enum/TypeEnum";
import ErrorCodeEnum from "../../enum/ErrorCodeEnum";
import TagsUtil from "../../util/TagsUtil";

class GetPatronFromRFID {

    constructor(jQuery, swal, languageManager) {
        this._jQuery = jQuery;
        this._swal = swal;
        this._languageManager = languageManager;

        this.append(this.getSelectorToAppend(), this.getObjectToAppend());
        this.attachEventHandler(this.getSelectorToEventHandle(), this.triggerScan.bind(this))
    }

    getBorrowerOrReject(borrowers) {
        if (borrowers.length === 0) {
            return Promise.reject({
                id: ErrorCodeEnum.NOT_FOUND,
                message: this._languageManager.resolveKey('patrons.notFound')
            });
        }

        if (borrowers.length > 1) {
            return Promise.reject({
                id: ErrorCodeEnum.TOO_MANY,
                message: this._languageManager.resolveKey('patrons.manyFound')
            })
        }

        return Promise.resolve(borrowers[0]);
    }

    addIdToFormField(id) {
        this._jQuery(this.getSelectorToSearchInput()).val(id);
    }

    submitForm() {
        this._jQuery(this.getSelectorToSearchSubmit()).click()
    }

    triggerScan($trigger) {
        $trigger.preventDefault();

        Promise.resolve(this._jQuery)
            .then(jQuery => new NetworkUtil(jQuery)
                .get(Config.API_END_POINT_TAGS)
                .then(tagsResponse => Tags.fromResponse(tagsResponse))
                .then(tagsObject => TagsUtil.getTagsOrReject(tagsObject)
                    .then(tags => TagsUtil.filterTags(tags, Type.PATRON))
                    .then(patrons => this.getBorrowerOrReject(patrons)
                        .then(patron => {
                            this.addIdToFormField(patron.pid);
                            this.submitForm();
                        })
                    )
                )
            )
            .catch(error => this._swal.fire({
                icon: 'error',
                title: error.status,
                text: error.message || this._languageManager.resolveKey('cantConnect'),
            }))
    }

    getObjectToAppend() {
        const btnText = this._languageManager.resolveKey('patrons.scan');
        return `<button id=\"autocscan\" class=\"scan submit\">${btnText}</button>`;
    }

    getSelectorToAppend() {
        return "#patronsearch > div.autocomplete > input.submit";
    }

    getSelectorToEventHandle() {
        return "#autocscan";
    }

    getSelectorToSearchInput() {
        return "#findborrower";
    }

    getSelectorToSearchSubmit() {
        return "#autocsubmit";
    }

    append(selector, objectToAppend) {
        return this._jQuery(objectToAppend).insertAfter(selector);
    }

    attachEventHandler(selector, handler) {
        return this._jQuery(selector).click(handler);
    }
}

export default GetPatronFromRFID;

'use strict';

export default {
    'languageName': 'Ελληνικά',
    'cantConnect': 'Δεν είναι δυνατή η σύνδεση με το διακομιστή RFID',

    'patrons': {
        'notFound': "Δεν βρέθηκαν πελάτες ... Δοκιμάστε ξανά ...",
        'manyFound': "Βρήκαμε πολλούς πελάτες ... Δοκιμάστε ξανά ...",
        'scan': 'Σάρωση RFID'
    },

    'items': {
        'notFound': "Δεν βρέθηκαν στοιχεία ... Δοκιμάστε ξανά ...",
        'scan': "Σάρωση RFID"
    }
}

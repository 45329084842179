class Tag {

    constructor(pid, type, description, error) {
        this._pid = pid;
        this._type = type;
        this._description = description;
        this._error = error;
    }

    static fromResponse(response) {
        return new Tag(
            response.pid,
            response.type,
            response.description,
            response.error,
        )
    }

    get pid() {
        return this._pid;
    }

    set pid(pid) {
        this._pid = pid;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get error() {
        return this._error;
    }

    set error(error) {
        this._error = error;
    }
}

export default Tag;

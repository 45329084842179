import CheckinFromRFIDAbstract from "./CheckinFromRFIDAbstract";

class CheckinFromRFIDHeader extends CheckinFromRFIDAbstract {

    constructor(jQuery, swal, languageManager) {
        super(jQuery, swal, languageManager);
    }

    getSelectorToAppend() {
        return "#checkin_search > form > input.submit";
    }

    getSelectorToEventHandle() {
        return "#autociscan";
    }

    getSelectorToSearchInput() {
        return "#checkin_search > form > input.head-searchbox";
    }
}

export default CheckinFromRFIDHeader;

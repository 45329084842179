'use strict';

import EN from './English'
import EL from './Ellinika'
import Config from "../Config";
import LanguageEnum from "../enum/LanguageEnum";

class LanguagesManager {

    constructor(currentLang) {
        this.supportedLanguages = new Map();
        this.supportedLanguages.set(LanguageEnum.EN, EN);
        this.supportedLanguages.set(LanguageEnum.EL, EL);

        this._currentLang = this.supportedLanguages.get(currentLang);
    }

    getDefaultLang() {
        return this.supportedLanguages.get(Config.DEFAULT_LANGUAGE);
    }

    getCurrentLang() {
        return this._currentLang;
    }

    resolveKey(originKey) {
        const defaultLang = this.getDefaultLang();
        const currentLang = this.getCurrentLang();

        const arrayOfKeys = this.getSubStringArray(originKey, '.');

        let resolvedString = '';
        let defaultLangObj = defaultLang;
        let currentLangObj = currentLang;

        arrayOfKeys.forEach((key, index, array) => {
            const isCurrentLangUndefined = this.isUndefined(currentLangObj);
            const isCurrentLangKeyUndefined = (isCurrentLangUndefined) ? true : this.isUndefined(currentLangObj[key]);
            const isCurrentLangUndefinedAtAll = (isCurrentLangUndefined || isCurrentLangKeyUndefined);

            const isDefaultLangUndefined = this.isUndefined(defaultLangObj);
            const isDefaultLangKeyUndefined = (isDefaultLangUndefined) ? true : this.isUndefined(defaultLangObj[key]);
            const isDefaultLangUndefinedAtAll = (isDefaultLangUndefined || isDefaultLangKeyUndefined);


            if (index < array.length - 1) {
                if (!(isCurrentLangUndefinedAtAll)) {
                    currentLangObj = currentLangObj[key]
                }

                if (!(isDefaultLangUndefinedAtAll)) {
                    defaultLangObj = defaultLangObj[key]
                }

                return
            }

            if (isCurrentLangUndefinedAtAll && isDefaultLangUndefinedAtAll) {
                resolvedString = originKey;
                return
            }

            if (isCurrentLangUndefinedAtAll) {
                resolvedString = defaultLangObj[key];
                return
            }

            resolvedString = currentLangObj[key]
        });

        return resolvedString
    };

    hasSubStrings(key, stringSplit) {
        return key.includes(stringSplit)
    };

    getSubStringArray(key, stringSplit) {
        if (!(this.hasSubStrings(key, stringSplit))) {
            return [key]
        }

        return key.split(stringSplit)
    };

    isUndefined(val) {
        return typeof val === undefined || typeof val === "undefined";
    }
}

export default LanguagesManager;

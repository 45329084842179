import CheckinFromRFIDAbstract from "./CheckinFromRFIDAbstract";

class CheckinFromRFIDInForm extends CheckinFromRFIDAbstract {

    constructor(jQuery, swal, languageManager) {
        super(jQuery, swal, languageManager);
    }

    getSelectorToAppend() {
        return "#checkin-form > div > fieldset > input.submit";
    }

    getSelectorToEventHandle() {
        return "#autocifscan";
    }

    getSelectorToSearchInput() {
        return "#checkin-form > div > fieldset > input.focus";
    }
}

export default CheckinFromRFIDInForm;

'use strict';


export default {
    'languageName': 'English',
    'cantConnect': 'Can\'t connect with RFID server',

    'patrons': {
        'notFound': "No patrons found... Try again...",
        'manyFound': "To many patrons found... Try again...",
        'scan': 'Scan RFID'
    },

    'items': {
        'notFound': "No items found... Try again...",
        'scan': "Scan RFIDs"
    }
}

import Tag from "./Tag";

class Tags {

    constructor(tags, error) {
        this._tags = Tags.parseTags(tags);
        this._tags = tags;
        this._error = error;
    }

    static parseTags(tags) {
        return tags.map(Tag.fromResponse);
    }

    static fromResponse(response) {
        return new Tags(
            response.tags,
            response.error,
        );
    }

    get tags() {
        return this._tags;
    }

    set tags(tags) {
        this._tags = tags;
    }

    get error() {
        return this._error;
    }

    set error(error) {
        this._error = error;
    }
}

export default Tags;

import ErrorCodeEnum from "../enum/ErrorCodeEnum";

function getTagsOrReject(tagsObject) {
    if (tagsObject.error !== "") {
        return Promise.reject({
            id: ErrorCodeEnum.ERROR,
            message: tagsObject.error
        })
    }

    return Promise.resolve(tagsObject.tags);
}

function filterTags(tags, type) {
    return Promise.resolve(tags.filter(value => value.type === type));
}

export default {
    getTagsOrReject: getTagsOrReject,
    filterTags: filterTags,
}

import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import GetPatronFromRFID from "./app/patrons/GetPatronFromRFID";
import MultipleCheckoutFromRFID from "./app/items/checkout/multiple/MultipleCheckoutFromRFID";
import LanguageUtil from "./util/LanguageUtil";
import Config from "./Config";
import LanguagesManager from "./lang/LanguagesManager";
import CheckinFromRFIDHeader from "./app/items/checkin/single/CheckinFromRFIDHeader";
import CheckinFromRFIDInForm from "./app/items/checkin/single/CheckinFromRFIDInForm";
import CheckoutFromRFIDInForm from "./app/items/checkout/single/CheckoutFromRFIDInForm";

let currentLanguage = LanguageUtil.getLanguage(jQuery) || Config.DEFAULT_LANGUAGE;
let languageManager = new LanguagesManager(currentLanguage);

new GetPatronFromRFID(jQuery, swal, languageManager);

new MultipleCheckoutFromRFID(jQuery, swal, languageManager);
new CheckoutFromRFIDInForm(jQuery, swal, languageManager);

new CheckinFromRFIDHeader(jQuery, swal, languageManager);
new CheckinFromRFIDInForm(jQuery, swal, languageManager);

class NetworkUtil {

    constructor(jQuery) {
        this._jQuery = jQuery;
    }

    get(url) {
        return this._jQuery.get(url);
    }
}

export default NetworkUtil;

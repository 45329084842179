import ErrorCodeEnum from "../../../../enum/ErrorCodeEnum";
import NetworkUtil from "../../../../util/NetworkUtil";
import Config from "../../../../Config";
import Tags from "../../../../model/Tags";
import TagsUtil from "../../../../util/TagsUtil";
import Type from "../../../../enum/TypeEnum";

class CheckinFromRFIDAbstract {

    constructor(jQuery, swal, languageManager) {
        this._jQuery = jQuery;
        this._swal = swal;
        this._languageManager = languageManager;

        this.append(this.getSelectorToAppend(), this.getObjectToAppend());
        this.attachEventHandler(this.getSelectorToEventHandle(), this.triggerScan.bind(this))
    }

    getItemOrReject(items) {
        if (items.length === 0) {
            return Promise.reject({
                id: ErrorCodeEnum.NOT_FOUND,
                message: this._languageManager.resolveKey('items.notFound')
            });
        }

        if (items.length > 1) {
            return Promise.reject({
                id: ErrorCodeEnum.TOO_MANY,
                message: this._languageManager.resolveKey('items.manyFound')
            })
        }

        return Promise.resolve(items[0]);
    }

    addIdToFormField(id) {
        this._jQuery(this.getSelectorToSearchInput()).val(id);
    }

    triggerScan($trigger) {
        $trigger.preventDefault();

        Promise.resolve(this._jQuery)
            .then(jQuery => new NetworkUtil(jQuery)
                .get(Config.API_END_POINT_TAGS)
                .then(tagsResponse => Tags.fromResponse(tagsResponse))
                .then(tagsObject => TagsUtil.getTagsOrReject(tagsObject)
                    .then(tags => TagsUtil.filterTags(tags, Type.ITEM))
                    .then(items => this.getItemOrReject(items)
                        .then(item => item.pid)
                        .then(this.addIdToFormField.bind(this))
                    )
                )
            )
            .catch(error => this._swal.fire({
                icon: 'error',
                title: error.status,
                text: error.message || this._languageManager.resolveKey('cantConnect'),
            }))
    }

    getObjectToAppend() {
        const id = this.getSelectorToEventHandle().substr(1);
        const btnText = this._languageManager.resolveKey('items.scan');
        return `<button id=\"${id}\" class=\"scan\">${btnText}</button>`;
    }

    getSelectorToAppend() {
        throw new Error('You have to implement the method getSelectorToAppend!');
    }

    getSelectorToEventHandle() {
        throw new Error('You have to implement the method getSelectorToEventHandle!');
    }

    getSelectorToSearchInput() {
        throw new Error('You have to implement the method getSelectorToSearchInput!');
    }

    append(selector, objectToAppend) {
        return this._jQuery(objectToAppend).insertAfter(selector);
    }

    attachEventHandler(selector, handler) {
        return this._jQuery(selector).click(handler);
    }
}

export default CheckinFromRFIDAbstract;

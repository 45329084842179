import CheckoutFromRFIDAbstract from "./CheckoutFromRFIDAbstract";

class CheckoutFromRFIDInForm extends CheckoutFromRFIDAbstract {

    constructor(jQuery, swal, languageManager) {
        super(jQuery, swal, languageManager);
    }

    getObjectToAppend() {
        const id = this.getSelectorToEventHandle().substr(1);
        const btnText = this._languageManager.resolveKey('items.scan');
        return `<button id=\"${id}\" class=\"scan btn btn-default\">${btnText}</button>`;
    }

    getSelectorToAppend() {
        return "#mainform > fieldset#circ_circulation_issue > button.btn.btn-default";
    }

    getSelectorToEventHandle() {
        return "#autocofscan";
    }

    getSelectorToSearchInput() {
        return "#mainform > fieldset#circ_circulation_issue > input.focus";
    }
}

export default CheckoutFromRFIDInForm;
